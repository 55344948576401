import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import Paper from '@mui/material/Paper';


export default function Politique() {
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Notre Politique"} />
            <Container sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: "100vw",
                    height: "85vh",
                    overflow: "auto"
                },
            }} component="main">
                <Paper elevation={3} style={{ padding: 10 }} >

                    <h2>
                        Introduction :
                    </h2>
                    <section>
                        Dans le cadre de son activité, la société ALTERIPSO, dont le siège social est situé au 5 Rue du Bulloz -74940 Annecy le vieux, est amenée à collecter et à traiter des informations dont certaines sont qualifiées de "données personnelles". La société AlterIpso attache une grande importance au respect de la vie privée, et n’utilise que des données de manière responsable et confidentielle et dans une finalité précise.
                    </section>
                    <h2>
                        Les données transmises directement :
                    </h2>
                    <section>
                        Dans le cadre de son activité, la société ALTERIPSO, dont le siège social est situé au 5 Rue du Bulloz -74940 Annecy le vieux, est amenée à collecter et à traiter des informations dont certaines sont qualifiées de "données personnelles". La société AlterIpso attache une grande importance au respect de la vie privée, et n’utilise que des données de manière responsable et confidentielle et dans une finalité précise.
                    </section>
                    <h2>
                        Base légale :
                    </h2>
                    <section>
                        Les données personnelles ne sont collectées qu’après consentement obligatoire de l’utilisateur. Ce consentement est valablement recueilli (boutons et cases à cocher), libre, clair et sans équivoque.
                    </section>
                    <h2>
                        Durée de conservation :
                    </h2>
                    <section>
                        Les données seront sauvegardées durant une durée maximale de 7 jours.
                    </section>
                    <h2>
                        Vos droits concernant les données personnelles :
                    </h2>
                    <section>
                        Vous avez le droit de consultation, demande de modification ou d’effacement sur l’ensemble de vos données personnelles. Vous pouvez également retirer votre consentement au traitement de vos données.
                        Contact délégué à la protection des données : contact@alteripso.com
                    </section>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}
