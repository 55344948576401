import './background.css';
import BackgroundImageLight from './images/bg.jpeg';
import BackgroundImageDark from './images/bgdark.jpg';

function Background() {
  var bgStyle = null;
  var PaleteColor = 'light';
  if (localStorage.getItem("CurrentVisionTheme") != null) {
    PaleteColor = localStorage.getItem("CurrentVisionTheme");
  }

  bgStyle = {
    backgroundImage: `url(${BackgroundImageLight})`,
    backgroundSize: "cover",
  };

  if (PaleteColor === "dark") {
    bgStyle = {
      backgroundImage: `url(${BackgroundImageDark})`,
      backgroundSize: "cover",
    };
  }

  return (
    <div style={bgStyle} className="Background">
    </div>
  );
}


export default Background;
