import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import configData from "../config/config.json";
import { isSessionValid, SendRabbitMessage } from '../helpers/helpers.js';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

export default function Applications() {
    const [idle, setIdle] = React.useState(localStorage.getItem("idle"));
    const navigate = useNavigate();
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    const [itemData, setitemData] = React.useState([]);
    useEffect(() => {
        if (isSessionValid()) {
            const localstoragedata = localStorage.getItem("tvdata");
            const tvlist = JSON.parse(localstoragedata).apps.ser;
            const finalapplist = [];
            for (var i = 0; i < tvlist.length; i++) {
                finalapplist.push({
                    img: configData.CDN_URL + 'ipso/web/uploads/' + tvlist[i].path,
                    title: tvlist[i].Application_name,
                    secondary: "",
                    key: "app" + i,
                    id: tvlist[i].id
                });
            }
            setitemData(finalapplist);
        }
    }, []);

    function StartApp(id, name) {
        if (name === idle) {
            name = name + " ";
        }
        setIdle(name);
        SendRabbitMessage('{"scene":"Apps","action":"' + id + '"}', navigate);
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Applications"} />
            <Container style={{ marginTop: "2vh", textAlign: "center" }} sx={{ mx: "auto" }} component="main">

                {itemData.map((item) => (
                    <List onClick={() => StartApp(item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar  variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText 
                                sx={{marginLeft:3}} 
                                primary={<Typography type="body2" style={{ color: 'black', fontWeight:"bold" }}>{item.title} </Typography>} />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}

                <Footer idle={idle} navigate={navigate} />
            </Container>
        </ThemeProvider>
    );
}
