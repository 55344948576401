import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import configData from "../config/config.json";
import axios from 'axios';
import { add, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { isSessionValid } from '../helpers/helpers.js';
import Footer from '../components/footer/footer.js';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Guide() {
    const [idle] = React.useState(localStorage.getItem("idle"));
    const navigate = useNavigate();

    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    const [itemData, setitemData] = React.useState([]);
    const [dateMod, setdateMod] = React.useState(0);
    const [descOpen, setDesc] = React.useState(false);
    const [currentChannel, setCurrentChannel] = React.useState("");
    const [currentChannelData, setCurrentChannelData] = React.useState([]);

    const handleDescOpen = () => {
        setDesc(true);
    };

    const handleDescClose = () => {
        setDesc(false);
    };

    function deductDate() {
        if (dateMod > 0) {
            setdateMod(dateMod - 1);
        }
    }

    function LoadData(channel) {
        setCurrentChannelData([]);
        setCurrentChannel(channel + " - Le " + format(add(new Date(), { days: dateMod }), 'dd/MM/yyyy', { locale: fr }));
        handleDescOpen();
        const start = format(add(new Date(), { days: dateMod }), 'yyyy-MM-dd 00:00:00', { locale: fr });
        const end = format(add(new Date(), { days: dateMod }), 'yyyy-MM-dd 23:59:59', { locale: fr });
        axios.post(configData.EPG_URL + 'index.php?api=true&channeldetails=' + encodeURIComponent(channel) + '&startdate=' + start + '&enddate=' + end)
            .then(response => {
                //console.log(response.data);
                setCurrentChannelData(response.data);
            })
            .catch(error => {
                //error
            });
    }

    useEffect(() => {
        if (isSessionValid()) {
            const localstoragedata = localStorage.getItem("epgdata");
            if (localstoragedata == null) {
                axios.post(configData.EPG_URL + 'index.php?api=true&channelslist=true')
                    .then(response => {
                        localStorage.setItem("epgdata", JSON.stringify(response.data));
                        setitemData(response.data);
                    })
                    .catch(error => {
                        //error
                    });
            } else {
                const finaltvlist = JSON.parse(localstoragedata);
                setitemData(finaltvlist);
            }
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Programmes TV"} />
            <Container style={{ marginTop: "2vh", textAlign: "center" }} sx={{ mx: "auto" }} component="main">
                <ButtonGroup sx={{ marginTop: 1 }} color="primary" size="medium" variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" onClick={() => { deductDate() }}>
                        <ArrowBackIosNewIcon />
                    </Button>
                    <Button variant="contained" onClick={() => { handleDescOpen() }}>
                        {format(add(new Date(), { days: dateMod }), 'ccc dd MMMM yyyy', { locale: fr })}
                    </Button>
                    <Button variant="contained" onClick={() => { setdateMod(dateMod + 1) }}>
                        <ArrowForwardIosIcon />
                    </Button>
                </ButtonGroup>
                {itemData.map((item) => (
                    <List onClick={() => LoadData(item.name)} key={item.id_channel} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={configData.EPG_URL + `${item.image}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.name} </Typography>} />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
                <Dialog
                    fullScreen
                    open={descOpen}
                    onClose={handleDescClose}
                >
                    <AppBar sx={{ position: 'fixed' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDescClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {currentChannel}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Toolbar />
                    {currentChannelData.map((item) => (
                        <List key={item.id_programme} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{ fontWeight: "bold", fontSize: "120%" }}
                                            component={'span'}
                                        >
                                            {item.title}
                                        </Typography>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{}}
                                                display="block"
                                                color="text.primary"
                                                component={'span'}
                                            >
                                                {format(new Date(item.start.replace(/ /g, 'T')), 'HH:mm', { locale: fr }) + " - " + format(new Date(item.stop.replace(/ /g, 'T')), 'HH:mm', { locale: fr })}
                                            </Typography>
                                            <Typography
                                                sx={{ fontWeight: "bold" }}
                                                display="block"
                                                color="text.secondary"
                                                component={'span'}
                                            >
                                                {item.category.toUpperCase()}
                                            </Typography>
                                            <Typography
                                                sx={{}}
                                                display="block"
                                                color="text.primary"
                                                component={'span'}
                                            >
                                                {item.description}
                                            </Typography>

                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </List>
                    ))}
                </Dialog>
                <Footer idle={idle} navigate={navigate} />
            </Container>
        </ThemeProvider >
    );
}
