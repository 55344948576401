import Notfound from './pages/404.js';
import Panel from './pages/panel.js';
import Auth from './pages/auth.js';
import Television from './pages/television.js';
import Politique from './pages/politique.js';
import Parametres from './pages/parametres.js';
import Radio from './pages/radio.js';
import Services from './pages/services.js';
import Charte from './pages/charte.js';
import ChartePdf from './pages/chartepdf';
import Applications from './pages/applications.js';
import Jeux from './pages/jeux.js';
import Guide from './pages/guide.js';
import Chromecast from './pages/chromecast.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:site/" element={<Auth />} />
        <Route path="/code" index element={<Auth />} />
        <Route path="/panel" element={<Panel />} />
        <Route path="/television" element={<Television />} />
        <Route path="/politique" element={<Politique />} />
        <Route path="/parametres" element={<Parametres />} />
        <Route path="/radio" element={<Radio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/charte" element={<Charte />} />
        <Route path="/chartepdf" element={<ChartePdf />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/jeux" element={<Jeux />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/chromecast" element={<Chromecast />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

