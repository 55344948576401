import React, { useEffect } from 'react';
import './header.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { isSessionValid, SendRabbitMessage } from '../../helpers/helpers.js';

export default function Header(props) {

    const navigate = useNavigate();



    const isBack = props.back;
    //const backLink = props.backlink;
    const title = props.title;

    const [roomName, setroomName] = React.useState("");

    useEffect(() => {
        if (!isSessionValid()) {
            localStorage.removeItem("tvdata");
            const site = localStorage.getItem("site");
            navigate('/' + site);
        } else {
            const localstoragedata = localStorage.getItem("tvdata");
            setroomName(JSON.parse(localstoragedata).tvdata.room);
        }
    }, [navigate]);



    const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
    const openLeftMenu = Boolean(anchorMenuEl);
    const handleMenuClick = (event) => {
        setAnchorMenuEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOff = () => {
        localStorage.removeItem("tvdata");
        const site = localStorage.getItem("site");
        navigate('/' + site);
        //localStorage.clear();
        //navigate('/');
    };

    function MuteUnmute() {
        SendRabbitMessage('{"scene":"VolumeControl","action":"MuteUnmute"}', navigate);
    }

    function IncreaseVolume() {
        SendRabbitMessage('{"scene":"VolumeControl","action":"Increase"}', navigate);
    }

    function ReduceVolume() {
        SendRabbitMessage('{"scene":"VolumeControl","action":"Reduce"}', navigate);
    }

    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? 'simple-popover' : undefined;



    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" className='panel'>

                <Toolbar>
                    <BackButton />
                    <IconButton
                        onClick={handleMenuClick}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <IconButton
                        onClick={handleClick}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <VolumeUpIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid container spacing={0} sx={{ mx: "auto" }}>
                    <Grid className="subHeaderGrid" item xs={4}>
                        <IconButton
                            className="VolumeButtons"
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => ReduceVolume()}
                        >
                            <RemoveRoundedIcon />
                        </IconButton>
                    </Grid>
                    <Grid className="subHeaderGrid" item xs={4}>
                        <IconButton
                            className="VolumeButtons"
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => MuteUnmute()}
                        >
                            <VolumeOffRoundedIcon />
                        </IconButton>
                    </Grid>
                    <Grid className="subHeaderGrid" item xs={4}>
                        <IconButton
                            className="VolumeButtons"
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => IncreaseVolume()}
                        >
                            <AddRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Popover>

            <Menu

                anchorEl={anchorMenuEl}
                id="account-menu"
                open={openLeftMenu}
                onClose={handleMenuClose}

                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <ListItemIcon>
                        <PersonRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Chambre : {roomName}
                </MenuItem>

                <MenuItem onClick={() => navigate('/parametres')}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Paramètres
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleLogOff}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Se déconnecter
                </MenuItem>
            </Menu>
        </Box>
    );

    function BackButton() {
        if (isBack) {
            return (<IconButton
                onClick={() => navigate(-1)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <ArrowBackIosNewIcon />
            </IconButton>);
        } else {
            return null;
        }

    }
}