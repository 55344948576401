import React, { useEffect } from 'react';
import './panel.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Footer from '../components/footer/footer.js';
import CookieConsent from "react-cookie-consent";
import { useNavigate } from 'react-router-dom';
import { isSessionValid } from '../helpers/helpers.js';
import ListItemButton from '@mui/material/ListItemButton';

export default function Panel() {
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });
    const [idle] = React.useState(localStorage.getItem("idle"));

    const navigate = useNavigate();
    const ChangeScene = (scene) => {
        switch (scene) {
            case 'tele':
                navigate('/television');
                break;
            case 'radio':
                navigate('/radio');
                break;
            case 'services':
                navigate('/services');
                break;
            case 'chartes':
                navigate('/charte');
                break;
            case 'Applications':
                navigate('/applications');
                break;
            case 'jeux':
                navigate('/jeux');
                break;
            case 'guide':
                navigate('/guide');
                break;
            case 'chromecast':
                navigate('/chromecast');
                break;
            default:
                return 'foo';
        }
    }

    const [itemData, setitemData] = React.useState([]);
    useEffect(() => {
        if (isSessionValid()) {
            const localstoragedata = localStorage.getItem("tvdata");
            const jsoncategorielist = JSON.parse(localstoragedata).categories;
            var finalcategorielist = [];
            if (jsoncategorielist.some(item => item.name === 'TELEVISION')) {
                finalcategorielist.push({
                    title: "Télévision",
                    name: "TELEVISION",
                    scene: "tele",
                    key: "tele",
                    color: "red"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'Radios')) {
                finalcategorielist.push({
                    title: "Radios",
                    name: "Radios",
                    scene: "radio",
                    key: "radio",
                    color: "#1fbd33"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'guide')) {
                finalcategorielist.push({
                    title: "Guide programmes",
                    name: "guide",
                    scene: "guide",
                    key: "epg",
                    color: "brown"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'chartes')) {
                finalcategorielist.push({
                    title: "Charte patient",
                    name: "chartes",
                    scene: "chartes",
                    key: "charte",
                    color: "#ff7e00"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'SERVICE')) {
                finalcategorielist.push({
                    title: "Services",
                    name: "SERVICE",
                    scene: "services",
                    key: "services",
                    color: "blue"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'Applications')) {
                finalcategorielist.push({
                    title: "Applications",
                    name: "Applications",
                    scene: "Applications",
                    key: "apps",
                    color: "#c96270"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'JEUX')) {
                finalcategorielist.push({
                    title: "Jeux",
                    name: "JEUX",
                    scene: "jeux",
                    key: "games",
                    color: "#ff00ea"
                });
            }

            if (jsoncategorielist.some(item => item.name === 'chromecast')) {
                finalcategorielist.push({
                    title: "Chromecast",
                    name: "chromecast",
                    scene: "chromecast",
                    key: "cast",
                    color: "#7bbb3e"
                });
            }
            setitemData(finalcategorielist);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={false} title={"Catégories"} />
            <Container style={{ marginTop: "2vh" }} sx={{ mx: "auto" }} component="main">
                {itemData.map((item) => (
                    <List onClick={() => ChangeScene(item.scene)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: item.color, borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "white" }}>
                        <ListItemButton>
                            <ListItem>
                                <img className="CatIcon" alt="Catégorie" src={require('../resources/' + item.key + '.png')} />
                                <Typography sx={{ marginLeft: "20px", fontWeight:"bold" }}> {item.title} </Typography>
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
                <Footer idle={idle} main={true} navigate={navigate} />
            </Container>
            <CookieConsent
                location="bottom"
                buttonText="Accepter"
                declineButtonText="Refuser"
                cookieName="privacy-cookie"
                style={{ background: "#2B373B", fontSize: "14px" }}
                buttonStyle={{ background: "green", color: "white", fontSize: "18px" }}
                declineButtonStyle={{ background: "red", color: "white", fontSize: "18px" }}
                enableDeclineButton={true}
                expires={4}
            >
                ALTER IPSO a mis à jour sa Politique de Confidentialité en lien avec l'entrée en application
                du nouveau Règlement Général sur la Protection des Données de l'Union Européene (RGPD). Nous
                utilisons des cookies qui sont nécessaires pour la bonne fonctionnalité de notre application.
                Après déconnexion de l'application, tous les cookies utilisés sont détruits systématiquement.
                <button onClick={() => navigate('/politique')} className="policyConf">Consulter notre politique de confidentialité</button>
            </CookieConsent>
        </ThemeProvider>
    );
}
