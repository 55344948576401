import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './404.css';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    const navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="notfoundId">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>Oops!</h1>
                    </div>
                    <h2>404 - Page introuvable</h2>
                    <p>La page que vous recherchez n'existe pas, a peut-être été supprimée ou est temporairement indisponible.</p>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => navigate('/panel')}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Page d'accueil
                    </Button>
                </div>
            </div>
        </ThemeProvider>
    );
}
