import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Footer from '../components/footer/footer.js';
import Header from '../components/header/header.js';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { SendRabbitMessage } from '../helpers/helpers.js';
import { useNavigate } from 'react-router-dom';

export default function Chromecast() {
    const [idle, setIdle] = React.useState(localStorage.getItem("idle"));
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }

    const navigate = useNavigate();
    const theme = createTheme({ palette: { mode: PaleteColor } });

    function LaunchChromecast() {
        let name = "Chromecast";
        if (name === idle) {
            name = name + " ";
        }
        setIdle(name);
        SendRabbitMessage('{"scene":"Chromecast","action":"Launch"}', navigate);
    }

    function CloseChromecast() {
        setIdle(null);
        SendRabbitMessage('{"scene":"HomeScene"}', navigate);
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Chromecast"} />
            <Container style={{ marginTop: "2vh", textAlign: "center" }} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} component="main">
                <ButtonGroup sx={{ marginTop: 2 }} color="success" size="large" variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" onClick={() => { LaunchChromecast() }}>
                        Lancer le Chromecast
                    </Button>
                </ButtonGroup>
                <ButtonGroup sx={{ marginTop: 5 }} color="success" size="large" variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" onClick={() => { CloseChromecast() }}>
                        Fermer le Chromecast
                    </Button>
                </ButtonGroup>
                <Footer idle={idle} navigate={navigate} />
            </Container>
        </ThemeProvider>
    );
}
