import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import configData from "../config/config.json";
import { isSessionValid, SendRabbitMessage } from '../helpers/helpers.js';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <Container
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: "100vw",
                    overflow: "auto"
                },
            }} component="main">

            {value === index && (
                <Container elevation={3} >
                    {children}
                </Container>
            )}
        </Container>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Radio() {
    const [idle, setIdle] = React.useState(localStorage.getItem("idle"));
    const navigate = useNavigate();
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    const [poprock, setpoprock] = React.useState([]);
    const [hits, sethits] = React.useState([]);
    const [infos, setinfos] = React.useState([]);
    const [culure, setculure] = React.useState([]);
    const [francaise, setfrancaise] = React.useState([]);
    const [electro, setelectro] = React.useState([]);
    const [hiphop, sethiphop] = React.useState([]);
    const [humour, sethumour] = React.useState([]);
    const [sport, setsport] = React.useState([]);
    const [musiquemonde, setmusiquemonde] = React.useState([]);

    useEffect(() => {
        if (isSessionValid()) {
            var poprockid, hitsid, infosid, cultureid, francaiseid, electroid, hiphopid, humourid, sportid, musiquemondeid;
            const localstoragedata = localStorage.getItem("tvdata");
            const radiocatslist = JSON.parse(localstoragedata).radiocat.cat;
            for (var j = 0; j < radiocatslist.length; j++) {
                switch (radiocatslist[j].Title) {
                    case 'pop/rock':
                        poprockid = radiocatslist[j].id;
                        break;
                    case 'hits':
                        hitsid = radiocatslist[j].id;
                        break;
                    case 'infos':
                        infosid = radiocatslist[j].id;
                        break;
                    case 'culture':
                        cultureid = radiocatslist[j].id;
                        break;
                    case 'française':
                        francaiseid = radiocatslist[j].id;
                        break;
                    case 'électro':
                        electroid = radiocatslist[j].id;
                        break;
                    case 'hip hop':
                        hiphopid = radiocatslist[j].id;
                        break;
                    case 'humour':
                        humourid = radiocatslist[j].id;
                        break;
                    case 'sport':
                        sportid = radiocatslist[j].id;
                        break;
                    case 'musique du monde':
                        musiquemondeid = radiocatslist[j].id;
                        break;
                    default:
                        break;
                }
            }


            const radiolist = JSON.parse(localstoragedata).radio.radio;

            const poprocklist = [];
            const hitslist = [];
            const infoslist = [];
            const culurelist = [];
            const francaiselist = [];
            const electrolist = [];
            const hiphoplist = [];
            const humourlist = [];
            const sportlist = [];
            const musiquemondelist = [];

            for (var i = 0; i < radiolist.length; i++) {
                if (radiolist[i].catradio_id === poprockid) {
                    poprocklist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === hitsid) {
                    hitslist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === infosid) {
                    infoslist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === cultureid) {
                    culurelist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === francaiseid) {
                    francaiselist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === electroid) {
                    electrolist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === hiphopid) {
                    hiphoplist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === humourid) {
                    humourlist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === sportid) {
                    sportlist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

                if (radiolist[i].catradio_id === musiquemondeid) {
                    musiquemondelist.push({
                        img: configData.CDN_URL + 'ipso/web/uploads/' + radiolist[i].path,
                        title: radiolist[i].Name,
                        secondary: radiolist[i].Country,
                        key: "radio" + i,
                        catid: radiolist[i].catradio_id,
                        id: radiolist[i].id
                    });
                }

            }
            setpoprock(poprocklist);
            sethits(hitslist);
            setinfos(infoslist);
            setculure(culurelist);
            setfrancaise(francaiselist);
            setelectro(electrolist);
            sethiphop(hiphoplist);
            sethumour(humourlist);
            setsport(sportlist);
            setmusiquemonde(musiquemondelist);
        }
    }, []);



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function StartRadio(catid, id, name) {

        if (name === idle) {
            name = name + " ";
        }
        setIdle(name);
        SendRabbitMessage('{"scene":"Radio","action":{"catid":"' + catid + '","id":"' + id + '"}}', navigate);
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Radios"} />
            <Box sx={{ bgcolor: 'background.paper', position: 'fixed', width: "100vw", zIndex: 999 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="pop/rock" {...a11yProps(0)} />
                    <Tab label="hits" {...a11yProps(1)} />
                    <Tab label="infos" {...a11yProps(2)} />
                    <Tab label="culture" {...a11yProps(3)} />
                    <Tab label="français" {...a11yProps(4)} />
                    <Tab label="électro" {...a11yProps(5)} />
                    <Tab label="hip hop" {...a11yProps(6)} />
                    <Tab label="humour" {...a11yProps(7)} />
                    <Tab label="sport" {...a11yProps(8)} />
                    <Tab label="musique du monde" {...a11yProps(9)} />
                </Tabs>
            </Box>
            <Box sx={{ visibility: "hidden" }}>
                <Tabs />
            </Box>
            <TabPanel value={value} index={0}>

                {poprock.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {hits.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {infos.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {culure.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={4}>
                {francaise.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={5}>
                {electro.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={6}>
                {hiphop.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={7}>
                {humour.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={8}>
                {sport.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <TabPanel value={value} index={9}>
                {musiquemonde.map((item) => (
                    <List onClick={() => StartRadio(item.catid, item.id, item.title)} key={item.key} sx={{ marginTop: 2, width: '100%', bgcolor: "white", borderRadius: "20px", padding: 0, boxShadow: "1px 1px 5px 1px #2c2c2c", color: "black" }}>
                        <ListItemButton sx={{ padding: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar variant="square" src={`${item.img}`} sx={{ width: 56, height: "auto" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ marginLeft: 3 }}
                                    primary={<Typography type="body2" style={{ color: 'black', fontWeight: "bold" }}>{item.title} </Typography>}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        </ListItemButton>
                    </List>
                ))}
            </TabPanel>
            <Footer idle={idle} navigate={navigate} />
        </ThemeProvider>
    );
}
