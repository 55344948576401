import React, { useEffect } from 'react';
import './auth.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Background from '../components/background/background.js';
import Loading from '../resources/loader.svg';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import { isSessionValid } from '../helpers/helpers.js';

import configData from "../config/config.json";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://alteripso.com/">
                ALTER IPSO
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}




export default function Auth() {

    const navigate = useNavigate();


    const [alertState, setAlertState] = React.useState({
        open: false,
        text: ""
    });

    const { site } = useParams();

    const [currentRoom, setCurrentRoom] = React.useState('');
    const [roomSuggestions, setroomSuggestions] = React.useState([]);
    const [Confirmopen, setConfirmOpen] = React.useState(false);
    const [LoaderVisible, SetLoader] = React.useState(false);

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleConfirmAccept = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const code = data.get('code').trim();

        if (code === "") {
            setAlertState({ open: true, text: "Veuillez insérer un code de confirmation valide." });
        } else {
            SetLoader(true);
            //TODO post call the code and check if valid
            const fulldata = { room: currentRoom, verifyKey: code };
            axios.post(configData.LOCAL_SERVER_URL + '?site=' + site + '&message=auth&response=true&action=auth&post=' + JSON.stringify(fulldata), null, { transformResponse: [] })
                //axios.post(configData.LOCAL_SERVER_URL + 'package/dauntless_logger/libs/philipsremote.php?action=auth&verifyKey=' + code, chambre, { transformResponse: [] })
                .then(response => {
                    SetLoader(false);
                    setAlertState({ open: false, text: alertState.text });
                    if (response.data === "false") {
                        setAlertState({ open: true, text: "Le code inséré est incorrect, veuillez réessayer." });
                    } else {
                        try {
                            const parsedJson = JSON.parse(response.data);
                            const authresponse = JSON.stringify(parsedJson);
                            localStorage.setItem("tvdata", authresponse);
                            navigate('/panel');
                        } catch (e) {
                            setAlertState({ open: true, text: "Une erreur s'est produite lors de l'accès au serveur, veuillez réessayer plus tard." });
                        }
                    }
                })
                .catch(error => {
                    SetLoader(false);
                    setAlertState({ open: true, text: "Une erreur s'est produite lors de l'accès au serveur, veuillez réessayer plus tard." });
                });

        }
    };

    const theme = createTheme();


    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const fulldata = { room: data.get('chambre'), generateKey: "true" };
        if (fulldata.room.trim() === "") {
            setAlertState({ open: true, text: "Veuillez insérer un numéro de chambre valide." });
        } else {
            SetLoader(true);
            handleConfirmOpen();
            setCurrentRoom(fulldata.room);
            axios.post(configData.LOCAL_SERVER_URL + '?site=' + site + '&message=auth&response=true&action=auth&post=' + JSON.stringify(fulldata))
                .then(response => {
                    setAlertState({ open: false, text: alertState.text });
                    if (response.data === false) {
                        handleConfirmClose();
                        setAlertState({ open: true, text: "Chambre introuvable, veuillez vérifier votre saisie ou sélectionner votre chambre dans la liste des suggestions." });
                    } else {
                        SetLoader(false);
                    }

                })
                .catch(error => {
                    handleConfirmClose();
                    setAlertState({ open: true, text: "Une erreur s'est produite lors de l'accès au serveur, veuillez réessayer plus tard." });
                });
        }

    };

    useEffect(() => {
        if (isSessionValid()) {
            navigate('/panel');
        } else {
            localStorage.setItem("site", site);
            axios.post(configData.LOCAL_SERVER_URL + '?site=' + site + '&message=getrooms&response=true&action=getrooms')
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setroomSuggestions(response.data);
                    }
                })
                .catch(error => {
                    //error loading suggestions list
                });
        }
    }, [navigate, site]);

    return (
        <ThemeProvider theme={theme}>
            <Background />
            <Container id="frontauth" component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 12,
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ fontWeight: "bold", fontSize: "28px" }}>
                        Authentification
                    </Typography>
                    <Typography sx={{ textAlign: "center", color: "#878787", marginTop: 2, fontSize: "18px" }} component="h3" variant="h5">
                        Inscrivez votre numéro de chambre pour vous identifier
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, textAlign: "center" }}>
                        <Autocomplete
                            freeSolo
                            disablePortal
                            id="combo-box-demo"
                            options={roomSuggestions}
                            noOptionsText="Pas d'options"
                            sx={{ width: 300 }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    sx={{ background: "white" }}
                                    margin="normal"
                                    autoFocus
                                    required
                                    fullWidth
                                    id="chambre"
                                    label="Numéro de chambre"
                                    name="chambre"
                                />
                            }
                        />

                        <Button
                            type="submit"

                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Valider
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Numéro de chambre oublié ?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 2, mb: 4 }} />
                <Dialog component="form" onSubmit={handleConfirmAccept} noValidate open={Confirmopen} onClose={handleConfirmClose}>
                    {LoaderVisible ? <img src={Loading} alt="Chargement.." /> : null}
                    {!LoaderVisible ?
                        <div>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Veuillez insérer le code de vérification affiché sur la télévision.
                                </DialogContentText>
                                <TextField
                                    type='number'
                                    autoComplete='off'
                                    required
                                    autoFocus
                                    margin="dense"
                                    id="code"
                                    name="code"
                                    label="Code"
                                    fullWidth
                                    variant="standard"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleConfirmClose}>Annuler</Button>
                                <Button type="submit">Valider</Button>
                            </DialogActions>
                        </div>
                        : null}
                </Dialog>
                <Fade onClick={() => { setAlertState({ open: false, text: alertState.text }) }} in={alertState.open} sx={{ position: "fixed", bottom: 10, zIndex: 5000, width: "80vw", left: "10vw" }}>
                    <Alert onClose={() => { setAlertState({ open: false, text: alertState.text }) }} variant="filled" severity="warning">
                        {alertState.text}
                    </Alert>
                </Fade>
            </Container>
        </ThemeProvider>
    );
}