import React, { useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Paper from '@mui/material/Paper';
import StopIcon from '@mui/icons-material/Stop';
import HomeIcon from '@mui/icons-material/Home';
import { SendRabbitMessage } from '../../helpers/helpers.js';
import './footer.css';

export default function Footer(props) {
    const [currentIdle, setcurrentIdle] = React.useState(props.idle);
    localStorage.setItem("idle", currentIdle);
    let BottomNavigationContent = null;

    if (currentIdle == null || currentIdle === undefined || currentIdle === "null") {
        let year = new Date().getFullYear();
        let label = "Copyright © " + year + " ALTERIPSO";
        BottomNavigationContent = <BottomNavigation showLabels sx={{}} >
            <BottomNavigationAction label={label} />
            <BottomNavigationAction sx={{color:"black", fontWeight:"bold"}} onClick={() => { stopCurrentPlay() }} label="Page principale" icon={<HomeIcon />} />
        </BottomNavigation>;
        if (props.main) {
            BottomNavigationContent = <BottomNavigation showLabels sx={{}} >
                <BottomNavigationAction label={label} />
                <BottomNavigationAction sx={{color:"black", fontWeight:"bold"}} onClick={() => { PowerMode() }} label="Power" icon={<PowerSettingsNewIcon />} />
            </BottomNavigation>;
        }
    } else {
        let year = new Date().getFullYear();
        let cr = "Copyright © " + year + " ALTERIPSO";
        let label = "Entrain de jouer : " + currentIdle;
        BottomNavigationContent = <BottomNavigation showLabels sx={{}} >
            <BottomNavigationAction label={cr} />
            <BottomNavigationAction sx={{color:"black", fontWeight:"bold"}} label={label} />
            <BottomNavigationAction sx={{color:"black", fontWeight:"bold"}} onClick={() => { stopCurrentPlay() }} label="Arrêter" icon={<StopIcon />} />
        </BottomNavigation>;
    }

    function stopCurrentPlay() {
        setcurrentIdle(null);
        SendRabbitMessage('{"scene":"HomeScene"}', props.navigate);
    }

    function PowerMode() {
        SendRabbitMessage('{"scene":"PowerMode"}', props.navigate);
    }

    useEffect(() => {
        setcurrentIdle(props.idle);
    }, [props.idle]);


    return (
        <div>
            <Paper sx={{ visibility: "hidden" }} elevation={3}>
                <BottomNavigation />
                <BottomNavigation />
            </Paper>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

                {BottomNavigationContent}

            </Paper>
        </div>
    );

}