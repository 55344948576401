import configData from "../config/config.json";
import axios from 'axios';
import { isBefore } from 'date-fns';

export function SendRabbitMessage(messageToBeSent, navigate) {
    console.log(messageToBeSent);
    if (!isSessionValid()) {
        return false;
    }
    const tvdata = JSON.parse(localStorage.getItem("tvdata"));
    const site = localStorage.getItem("site");
    const fulldata = { Key: tvdata.session_id, message: messageToBeSent };
    axios.post(configData.LOCAL_SERVER_URL + '?site=' + site + '&response=false&message=SendRabbitMessage&action=SendRabbitMessage&post=' + JSON.stringify(fulldata))
        //axios.post(configData.LOCAL_SERVER_URL + 'package/dauntless_logger/libs/philipsremote.php?action=SendRabbitMessage&Key=' + tvdata.session_id, rabbitMessage)
        .then(response => {
            //console.log(response.data);
            if (!response.data) {
                localStorage.removeItem("tvdata");
                const site = localStorage.getItem("site");
                navigate('/' + site);
                //localStorage.clear();
                //navigate('/');
            }
        })
        .catch(error => {
            console.log("error");
        });
}

export function isSessionValid() {
    var response = true;
    if (localStorage.getItem("tvdata") == null || localStorage.getItem("site") == null) {
        response = false;
    } else {
        try {
            const tvdata = JSON.parse(localStorage.getItem("tvdata"));
            response = isBefore(new Date(tvdata.session_expiration), new Date());
        } catch (e) {
            response = false;
        }
    }
    return response;
}
