import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer/footer.js';
//import pdf from '../resources/charte.pdf';
import { SendRabbitMessage } from '../helpers/helpers.js';

export default function Charte() {
    const [idle, setIdle] = React.useState(localStorage.getItem("idle"));
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    const navigate = useNavigate();

    /*useEffect(() => {
        let name = "Charte patient";
        setIdle(name);
        SendRabbitMessage('{"scene":"Charte","action":"Launch"}', navigate);
    }, [navigate]);*/

    function SwitchPage(direction) {
        SendRabbitMessage('{"scene":"Charte","action":"' + direction + '"}', navigate);
    }

    function LaunchPdf() {
        let name = "Charte patient";
        if (name === idle) {
            name = name + " ";
        }
        setIdle(name);
        SendRabbitMessage('{"scene":"Charte","action":"Launch"}', navigate);
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Charte Patient"} />
            <Container style={{ marginTop: "2vh", textAlign: "center" }} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} component="main">
                <ButtonGroup color="warning" size="large" variant="contained" aria-label="outlined primary button group">
                    <Button variant="contained" onClick={() => { SwitchPage("Previous") }}>
                        <ArrowLeftIcon />
                    </Button>
                    <Button variant="contained" onClick={() => { LaunchPdf() }}>
                        Lancer
                    </Button>
                    <Button variant="contained" onClick={() => { SwitchPage("Next") }}>
                        <ArrowRightIcon />
                    </Button>
                </ButtonGroup>
                <ButtonGroup sx={{ marginTop: 5 }} color="warning" size="large" variant="contained" aria-label="outlined primary button group">
                    <Button onClick={() => navigate("/chartepdf")} variant="contained">
                        Lancer le PDF sur cet appareil
                    </Button>
                </ButtonGroup>
                <Footer idle={idle} navigate={navigate} />
            </Container>
        </ThemeProvider>
    );
}
