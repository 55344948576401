import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../components/background/background.js';
import Header from '../components/header/header.js';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdf from '../resources/charte.pdf';

import configData from "../config/config.json";

export default function Charte() {
    var PaleteColor = 'light';
    if (localStorage.getItem("CurrentVisionTheme") != null) {
        PaleteColor = localStorage.getItem("CurrentVisionTheme");
    }
    const theme = createTheme({ palette: { mode: PaleteColor } });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Background />
            <Header back={true} backlink={'/panel'} title={"Charte Patient"} />

            <Worker workerUrl={configData.LOCAL_SERVER_URL + "package/dauntless_logger/libs/pdf.worker.min.js"}>
                <Container style={{ marginTop: "2vh", textAlign: "center" }} sx={{ mx: "auto" }} component="main">
                    <Viewer fileUrl={pdf} />
                </Container>
            </Worker>

        </ThemeProvider>
    );
}
